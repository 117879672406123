import { Auth0Client } from '@auth0/auth0-spa-js';
import { isAuthenticated, user } from "$lib/services/store.js";

export const auth0 = new Auth0Client({
  domain: `${import.meta.env.VITE_AUTH0_DOMAIN}`,
  // clientId is issued by Auth0 for this application
  clientId: `${import.meta.env.VITE_AUTH0_CLIENT_ID}`,
  authorizationParams: {
    // needs to be changed along with the same property in Auth0 dashboard
    redirect_uri: `${import.meta.env.VITE_AUTH0_REDIRECT_URI}/`,
  }
});

export const loginAuth0 = async () => {
  await auth0.loginWithRedirect();
}

export const logoutAuth0 = async () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('id_token');
  isAuthenticated.set(false);
  user.set(null);

  auth0.logout({ returnTo: window.location.pathname });
}
