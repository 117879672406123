<script>
  import { onMount } from "svelte";
  import { isAuthenticated, user } from "$lib/services/store.js";
  import { auth0 } from "$lib/services/auth0.js";

  // remove after VITE_ENABLE_AUTH0 ff cleanup
  const isAuth0Enabled = import.meta.env.VITE_ENABLE_AUTH0 === 'true';

  onMount(async () => {
    if (!isAuth0Enabled) {
      return;
    }

    if (window.location.search.includes('code=')) {
      await auth0.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const authStatus = await auth0.isAuthenticated();

    if (authStatus) {
      const userData = await auth0.getUser();

      isAuthenticated.set(authStatus);
      user.set(userData);

      const token = await auth0.getTokenSilently();
      const idToken = await auth0.getIdTokenClaims();
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('id_token', idToken?.__raw);
    } else {
      const token = sessionStorage.getItem('token');
      const idToken = sessionStorage.getItem('id_token');

      isAuthenticated.set(Boolean(token));
      user.set(idToken);
    }
  });
</script>

<slot />
